.login-container {
  display: flex;
  position: relative;
  height: 100vh;
}

.login-bg-logo {
  position: absolute;
  width: 200px;
  height: 54px;
  background: url("../assets/logo.png") no-repeat center center;
  margin: 23px 31px;
}

.login-bg-right {
  position: absolute;
  right: 0;
  width: 500px;
  height: 472px;
  background: url("../assets/right.png") no-repeat;
}

.login-bg-left {
  position: absolute;
  width: 500px;
  height: 118px;
  background: url("../assets/left.png") no-repeat;
  bottom: 0;
}

.login-form {
  margin: auto;
}

.login-text-h1 {
  margin: auto;
  text-align: center;
  width: 300px;
  height: 65px;
  font-size: 55px;
  font-weight: bold;
  color: #002e73;
}

.login-text-h2 {
  margin: auto;
  text-align: center;
  width: 300px;
  height: 85px;
  font-size: 45px;
  font-weight: bold;
  color: #002e73;
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 39px;
  padding-top: 1px;
  overflow: hidden;
  zoom: 1;
  margin: 0;
  line-height: 39px;
  background: #fff;
}

.login-container__footer {
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.login-container__footer__item {
  display: inline;
  margin-right: 20px;
  color: #bbb;
  font-size: 12px;
}
.login-container__footer__item a {
  text-decoration: none;
  color: #bbb;
}