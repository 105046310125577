.ant-table-list {
  height: calc(100vh - 104px);
}

.ant-table-list > tr > th{
white-space:nowrap;
}

.ant-table-list td{
white-space:nowrap;
}
