.tabs-container {
  height: calc(100vh - 60px);
  width: 100%;
}

.ant-tabs-bar {
  height: 40px;
  border: none;
}

.ant-tabs-tabpane-active {
  height: calc(100vh - 104px);
}

.ant-tabs-bar {
  margin: 0;
}