.app-container {
  height: 100%;
  overflow: hidden;
}

.app-header {
  display: flex;
  line-height: normal;
  height: 60px;
  position: relative;
}

.app-logo {
  position: relative;
  width: 86px;
  height: 32px;
  background: url('../assets/white-logo.png') no-repeat;
  margin: 10px;
}

.app-info {
  position: absolute;
  right: 50px;
  margin-top: 15px;
}

.app-right-bar {
  border-top: 1px solid #f0f2f5;
  background-color: #fff;
}

.app-right-bar.hidden {
  display: none;
}


